import configJson from './config.json';
import { valiRule, errorMsgEnum } from './validator.js';
import { pageEnum, tabBarMenu, needLoginRouter } from './pages.js';

// 素材库
// dia_recharge1  首页充值弹窗1
// dia_recharge2  首页充值弹窗2
// dia_first_recharge 首次打开游戏弹窗
// share 分享图片 png格式，未修改
// footer_gys 页面底部合作伙伴
// recharge_guide 充值引导弹窗
// register_giveaway1 register_giveaway2 注册活动页面
// recharge_giveaway1 recharge_giveaway2 充值活动页面
// app_register_activity_notice app登录奖励
// app_download 下载app
// clabe_bg3 // 充值页clabe弹窗
// roulette_banner 大转盘banner
// recharge_img_01 充值页icon
// moneyComp_banner 损失补偿banner
// moneyFullRefund_banner 包赔活动banner
// discountCode_banner  彩金折扣码banner

//
// 下载页
// toutu2 text1 text2 text3 appic1 appic2 appic3 appic4 appic5 appic6 10张
/**
 * 项目变量配置文件config
 */
// #ifdef H5
// #endif
// #ifdef APP-PLUS
// #endif

const Env = {
  release: 'release',
  staging: 'staging',
  test: 'test',
  local: 'local',
  proxy: 'proxy',
};

var config = {
  _env: '',
  _localEnv: '',
  _path: '',
  _defaultPath: '',
  _loginPath: '',
  _homePath: '',
  _name: '',
  _link: '',
  _key: '',
};

/***********************************/
/*                                 */
/*        唯一需要更改的配置env       */
/*                                 */
/***********************************/
/**
 * 需要手动配置的环境变量
 * release/staging/test
 */

// --- ！！！！ 以下请勿修改【configEnvTest，configEnvRelease，var env =  Env.release;
// 接口返回整理：
// 当前渠道：CONFIG_CHANEL

// 渠道对应推广参数
// s: 3,
// pixel: '306579948500532',
// acc: '10002',

// 下载页渠道：DOWNLOADP_PAGE_CHANEL
// 推广页渠道：PROMOTION_PAGE_CHANEL
// api路径：
// hostName：
// socket路径：
// 网站路径：
// pc展示路径

// app通过渠道来获取接口请求地址，环境如何设置？
// h5通过国家来获取接口请求地址

// 后台语言（es_CO）对应本地语言json文件（co.json、uni-app.co.json）
var languageEnum = {
  US: 'en',
  MX: 'es',
  CO: 'co',
  BR: 'br',
  NG: 'ng',
  PE: 'pe',
  CL: 'cl',
  ID: 'id',
  EC: 'ec',
};
// 网站枚举
var siteEnum = { dev: ['localhost', '127.0.0.1'] }; // 添加上本地开发
for (let key in configJson) {
  siteEnum[key] = [configJson[key]['test']['_hostName'], configJson[key]['release']['_hostName']];
  siteEnum[key].push(configJson[key]['release']['_hostName'].substring(4)); // 兼容输入的网址不带‘www.’的情况
}

/**
 * 处理chanel()
 * 365gana_10000_0_-1(渠道：365gana_10000， s:0, pixel:-1[代表0])
 * 365gana_10002_3_306579948500532(渠道：365gana_10000， s:3, pixel:306579948500532)
 */
function handleChannel(chanel) {
  let chanelObj = {};
  // #ifdef H5
  chanelObj = {
    CONFIG_CHANEL: '',
    // channel: chanel || DEFAULT_PAGE_CHANEL, // 首页底部下载app
    channel: chanel, // 首页底部下载app
    s: '',
    acc: '',
    pixel: '',
  };
  // #endif
  // #ifdef APP-PLUS
  let appChannel = plus.runtime.channel.split('_');
  chanelObj = {
    CONFIG_CHANEL: appChannel[0],
    channel: `${appChannel[0]}_${appChannel[1]}`,
    acc: appChannel[1],
    s: appChannel[2],
    pixel: appChannel[3] == -1 ? '' : appChannel[3],
  };
  // #endif

  // if (getQueryString('siteType') === 'w2a' || getLocalData('siteType')==='w2a') {
  //   chanelObj.channel = getQueryString('channel')
  // }

  return chanelObj;
}

// gana100
// suerte777
// gananciatotal
// jugar777
// mxgana
// suerteco
// samba100
/**
 * ！！！！！！！！脚本打包会替换以下代码 勿动！！！！！！！！
 * let CONFIG_CHANEL = "tonace";
 */
let CONFIG_CHANEL = "tonace";
let defaultChannel = JSON.parse(JSON.stringify(CONFIG_CHANEL));

// #ifdef APP-PLUS
// TODO: 本地调试的时候注释掉，上线前放开
let handleChannelRes = handleChannel();
CONFIG_CHANEL = handleChannelRes.CONFIG_CHANEL ? handleChannelRes.CONFIG_CHANEL : defaultChannel;
// #endif

const DEFAULT_PAGE_CHANEL = configJson[CONFIG_CHANEL]['_default_channel'];
const PROMOTION_PAGE_CHANEL = configJson[CONFIG_CHANEL]['_promotion_page_channel'];

// const configEnvTest = configJson[CONFIG_CHANEL]['test']['_hostName'];
// const configEnvRelease = configJson[CONFIG_CHANEL]['release']['_hostName'];
// TODO: 1、隐私政策用户协议地址替换 2、soft游戏返回html地址修改
// var env =  Env.release;
var env =  Env.release;
// var env =  Env.release;
// 是否是pwa,启用apiHost
var ispwa = false;

(function getPath(config, env) {
  const CONFIG_ENV_CHANEL = configJson[CONFIG_CHANEL][env];
  let hostName = CONFIG_ENV_CHANEL['_hostName'];
  if (env == 'test' && location.hostname == 'localhost') {
    hostName = location.host;
  }
  const filePath = CONFIG_ENV_CHANEL['_filePath'];
  const apiHost = ispwa ? CONFIG_ENV_CHANEL['_apiHost'] : ''; // TODO: ---3pwa调试
  const miniAppName = CONFIG_ENV_CHANEL?._name || '';
  const miniAppLink = CONFIG_ENV_CHANEL?._link || '';

  config._env = env;
  config._localEnv = `${hostName}_`;
  config._key = CONFIG_CHANEL;

  // #ifdef APP-PLUS
  config._apiHost = apiHost;
  config._apiPath = `https://${apiHost || hostName}/proxy-api`; // api路径
  config._socketPath = `wss://${apiHost || hostName}/proxy-api/config/api/front/push`; // socket路径
  config._hostName = hostName;
  config._homePath = `https://${hostName}/`; // 网站路径
  config._filePath = `https://${filePath}/statics/asserts`; // 图片路径
  // #endif

  // #ifdef H5
  config._apiHost = apiHost;
  config._apiPath = `${location.protocol}//${apiHost || hostName}/proxy-api`; // api路径
  config._socketPath = `wss://${apiHost || hostName}/proxy-api/config/api/front/push`; // socket路径
  config._hostName = hostName;
  config._homePath = `https://${hostName}/`; // 网站路径
  config._filePath = `https://${filePath}/statics/asserts`; // 图片路径
  config._name = miniAppName;
  config._link = miniAppLink;
  // #endif

  // 网站首页名称
  // config._homePath = `/`;
})(config, env);

export default config;

// android studio 打包渠道示例 -1代表pixel为空字符串
// productFlavors{
//     '365gana_10000'{
//         applicationId "com.talara.gana365"
//         manifestPlaceholders = [DCLOUD_STREAMAPP_CHANNEL: "com.talara.gana365|__UNI__DDB6540|365gana_10000_0_-1"]
//     }
//     '365gana_10002'{
//         applicationId "com.talara.gana365"
//         manifestPlaceholders = [DCLOUD_STREAMAPP_CHANNEL: "com.talara.gana365|__UNI__DDB6540|365gana_10002_3_306579948500532"]
//     }
// }

/**   ---项目逻辑配置---  */

const _publicKeyPEM = configJson[CONFIG_CHANEL]['_publicKeyPEM'];
// let WEBSITE_ID = configJson[CONFIG_CHANEL]['WEBSITE_ID'];
let WEBSITE_ID = '10d60a2f-01cc-451f-92fd-2973ea9abea9';

export {
  handleChannel,
  CONFIG_CHANEL,
  DEFAULT_PAGE_CHANEL,
  PROMOTION_PAGE_CHANEL,
  valiRule,
  errorMsgEnum,
  WEBSITE_ID,
  _publicKeyPEM,
  pageEnum,
  tabBarMenu,
  needLoginRouter,
  siteEnum,
  languageEnum,
};
